.page-styles {
    font-size: 1em;
    line-height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 600px) and (min-height: 1100px) {
    .page-styles {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
}

h1 {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
}

h2 {
    font-weight: bold;
    font-size: 1.4em;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-bottom: 30px;
    padding: 0 20px;
}

.top-section {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
}

.bottom-section {
    display: flex;
    justify-content: center;
    gap: 50px;
}

@media only screen and (max-width: 700px) {
    .bottom-section {
        flex-wrap: wrap;
    }
}

.left-section {
    max-width: 500px;
    min-width: 300px;
    width: 100%;
}

.right-section {
    max-width: 500px;
    min-width: 300px;
    width: 100%;
}

.reading-section {
    font-weight: lighter;
}

.skill-group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;
}

.skill {
    background-color: var(--platinum);
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 15px var(--platinum);
    font-size: 0.85em;
}

.contact-button {
    background-color: var(--primary-color);
    padding: 15px 45px;
    width: fit-content;
    border-radius: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--white-color);
    margin-top: 30px;
    text-shadow: 0 0 5px var(--primary-color);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    cursor: pointer;
}

.inline-link {
    font-weight: bold;
    cursor: pointer;
    color: var(--primary-color);
}