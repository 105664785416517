.page-styles {
    font-size: 1em;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-bottom: 30px;
    padding: 0 20px;
}

.top-section {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
}

.bottom-section {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 880px;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    background-color: var(--platinum);
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 20px;
    max-width: 800px;
}

.text-input {
    height: 60px;
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 10px;
    font-size: 1em;
    color: var(--dark-grey);
}

.message-area {
    width: 100%;
    height: 180px;
    resize: none;
    border-radius: 5px;
    border: none;
    padding: 10px;
    font-size: 1em;
    color: var(--dark-grey);
}

.reading-section {
    font-weight: lighter;
}

.submit-button {
    background-color: var(--primary-color);
    max-width: 250px;
    height: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--white-color);
    border-radius: 5px;
    font-size: 1.25em;
    margin-top: 30px;
    text-shadow: 0 0 5px var(--primary-color);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    background-image: linear-gradient(to right, var(--dark-primary) , var(--primary-color));
    transition: all .4s ease-in-out;
    cursor: pointer;
}

.submit-button:hover {
    color: var(--alternate-color);
    box-shadow: 0 0 15px var(--alternate-color);
    transition: 0.5s;
}

.submit-button-disabled {
    background-color: var(--dark-grey);
    max-width: 250px;
    height: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--white-color);
    border-radius: 5px;
    font-size: 1.25em;
    margin-top: 30px;
    text-shadow: 0 0 5px var(--primary-color);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    background-image: linear-gradient(to right, var(--mid-grey) , var(--grey));
    transition: all .4s ease-in-out;
    cursor: wait;
}

.input-label {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 0.85em;
    letter-spacing: 1px;
}

.input-label:first-child {
    margin-top: 0;
}

.submitted-logo {
    color: var(--primary-color);
    font-size: 120px;
}

.form-submitted {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 30px 20px;
}

.form-submitted p {
    margin: 0;
}

.form-submitted h2 {
    margin: 10px;
    font-size: 2em;
}

.acknowledge-button {
    background-color: var(--primary-color);
    padding: 15px 45px;
    width: fit-content;
    border-radius: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--white-color);
    margin-top: 30px;
    text-shadow: 0 0 5px var(--primary-color);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    text-align: center;
    transition: all .4s ease-in-out;
    cursor: pointer;
}

.acknowledge-button:hover {
    color: var(--alternate-color);
    box-shadow: 0 0 15px var(--alternate-color);
    transition: 0.5s;
}

@media only screen and (max-width: 599px) {
    .submit-button {
        max-width: 100%;
    }

    .acknowledge-button {
        width: 100% !important;
    }
}