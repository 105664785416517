.page-styles {
    font-size: 1em;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-bottom: 30px;
}

.top-section {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    text-align: center;
    margin: 0 auto;
    padding: 0 20px;
}

.bottom-section {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1350px;
    width: 100%;
    margin: 0 auto;
}

.reading-section {
    font-weight: lighter;
    margin: 20px 0;
    line-height: 1.5em;
}

.project-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
    cursor: pointer;
    background: rgba(72,49,212, 0.95);
    backdrop-filter: blur(1px);
}

.project-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.projects-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 20px;
}

.project-name {
    font-size: 1.5em;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white;
    transition: .45s ease-in-out;
}

.project-container:hover .project-name {
    color: var(--alternate-color);
    transition: .15s ease-in-out;
}

.project-container:hover .tech-icon {
    color: var(--platinum);
    transition: .15s ease-in-out;
}

.project-container:hover .chevron-icon {
    color: var(--alternate-color);
    transition: .15s ease-in-out;
    animation: swing 1s ease;
    animation-iteration-count: 1;
}

.tech-icon {
    font-size: 1.5em;
    margin-top: 10px;
    margin-right: 20px;
    color: var(--alternate-color);
    transition: .45s ease-in-out;
}

.chevron-icon {
    font-size: 1.5em;
    color: var(--white-color);
    transition: .45s ease-in-out;
}

@keyframes swing
{
    15%
    {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }
    30%
    {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
    }
    50%
    {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
    }
    65%
    {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }
    80%
    {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }
    100%
    {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@media only screen and (max-width: 500px) {
    .project-name, .tech-icon {
        font-size: 1.25em;
    }
}