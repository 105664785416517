.page-styles {
    font-size: 1em;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-bottom: 30px;
}

.top-section {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    text-align: center;
    margin: 0 auto;
    padding: 0 20px;
}

.bottom-section {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1350px;
    width: 100%;
    margin: 0 auto;
}

.reading-section {
    font-weight: lighter;
    margin: 10px 0;
    line-height: 1.5em;
}

.reading-section:first-child {
    margin-top: 20px;
    margin-bottom: 0 !important;
}

.grid-section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0.75em;
}

.spacer {
    padding: 0.75em;
    width: 33.333%;
}

@media only screen and (max-width: 1050px) {
    .spacer {
        width: 50%;
    }
}

@media only screen and (max-width: 700px) {
    .spacer {
        width: 100%;
    }
}

.grid-item {
    position: relative;
}

.grid-image {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 5px;
    background-color: var(--dark-grey);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.grid-item:hover .overlay {
    opacity: 1;
    transition: .45s ease-in-out;
}

.overlay {
    position: absolute;
    opacity: 0;
    background-color: rgb(48 32 151 / 98%);
    border-radius: 5px;
    padding: 10px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.25em;
    transition: .45s ease-in-out;
}

.overlay-text {
    color: var(--white-color);
    font-size: 1em;
    margin: 0;
    text-align: center;
}

.overlay-button {
    background-color: var(--primary-color);
    padding: 15px 45px;
    width: fit-content;
    border-radius: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--white-color);
    text-shadow: 0 0 5px var(--primary-color);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    cursor: pointer;
}

.inline-link {
    font-weight: bold;
    cursor: pointer;
    color: var(--primary-color);
}