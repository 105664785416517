.circle-shadow {
    width: 80px;
    height: 80px;
    background-color: var(--primary-color);
    border-radius:50%;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    margin: 15px 20px;
    transition: all .4s ease-in-out;
}

.circle-shadow:hover {
    box-shadow: 0 0 15px var(--primary-color);
    transition: 0.5s;
}

.active {
    color: var(--primary-color);
    border-color: var(--primary-color);
}

.inactive {
    color: var(--dark-grey);
}

.full-nav {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    max-width: var(--max-content-area);
    position: relative;
    z-index: 1;
}

.nav-logo {
    font-size: 21px;
    margin-right: 35px;
}

.full-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0 20px;
    display: flex;
    gap: 20px;
}

.full-nav ul li {
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-nav ul li a {
    padding: 10px 20px;
    text-decoration: none;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.5px;
    transition: 0.5s;
    position: relative;
}

.full-nav ul li a::after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 1px;
    border-width: 0 0 3px;
    border-style: solid;
    border-radius: 3px;
    border-color: var(--primary-color);
}

.full-nav ul li a:hover {
    transition: 0.5s;
    color: var(--primary-color);
    text-shadow: 0 0 30px var(--primary-color);
}

/* Mobile Menu */

.activeMobile {
    background-color: var(--mid-grey);
    color: var(--alternate-color) !important;
}

.mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
}

.menu-icon {
    margin: 15px 20px;
    cursor: pointer;
    padding: 10px;
    font-size: 50px;
    color: var(--primary-color);
}

.dark-layer {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    height: 100vh;
    width: 100vw;
    cursor: pointer;
    transition: transform .3s cubic-bezier(0, .52, 0, 1);
    transform: translateX(0%);
}

.hide-layer {
    position: fixed;
    transition: transform .3s cubic-bezier(0, .52, 0, 1);
    transform: translateX(-100%);
}

.menu-layer {
    background-color: var(--dark-grey);
    width: 80%;
    height: 100%;
    padding: 0;
    color: var(--white-color);
    cursor: default;
}

.menu-heading {
    margin: 0;
    padding: 30px 0;
    text-align: center;
}

.menu-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu-list li {
    height: 60px;
    border-bottom: 1px solid var(--white-color);
    text-transform: uppercase;
    letter-spacing: 1px;
}

.menu-list li:first-child {
    border-top: 1px solid var(--white-color);
}

.menu-list li a {
    color: var(--white-color);
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.version {
    position: absolute;
    bottom: 10px;
    left: 20px;
    font-size: 0.75em;
}