.page-styles {
    font-size: 1em;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-box {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 20px;
    overflow: hidden;
}

@keyframes drop-left {
    from {
     opacity: 0;
     transform: translateX(-500px);
    }
    to {
     opacity: 1;
     transform: translate(0px);
    }
}

@keyframes drop-bottom {
    from {
     opacity: 0;
     transform: translateY(400px);
    }
    to {
     opacity: 1;
     transform: translate(0px);
    }
}

.dev-name {
    margin: 0 0 5px 0;
    font-size: 50px;
    font-weight: bold;
    text-transform: uppercase;
    animation: drop-left 1000ms ease 50ms backwards;
}

.dev-title {
    font-size: 0.75em;
    animation: drop-left 1100ms ease 200ms backwards;
}

.description {
    animation: drop-left 1200ms ease 400ms backwards;
}

.resume-button {
    background-color: var(--primary-color);
    width: 250px;
    height: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--white-color);
    border-radius: 5px;
    font-size: 1.25em;
    margin-top: 15px;
    text-shadow: 0 0 5px var(--primary-color);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    background-image: linear-gradient(to right, var(--dark-primary) , var(--primary-color));
    transition: all .4s ease-in-out;
    animation: drop-left 1300ms ease 600ms backwards;
}

.resume-button:hover {
    color: var(--alternate-color);
    box-shadow: 0 0 15px var(--alternate-color);
    transition: 0.5s;
}

.social-menu {
    background-color: var(--primary-color);
    width: 60px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: var(--white-color);
    border-radius: 5px;
    position: absolute;
    right: 10px;
    box-shadow: 0 0 5px var(--primary-color);
    transition: 0.5s;
    /* animation: drop-bottom 1500ms ease 800ms backwards; */
}

.social-menu:hover {
    color: var(--alternate-color);
    box-shadow: 0 0 15px var(--alternate-color);
    transition: 0.5s;
}

@media only screen and (max-width: 768px) {
    .social-menu {
        bottom: 10px;
        right: auto;
        height: 60px;
        max-width: 300px;
        width: 100%;
        flex-direction: row;
    }
}

@media only screen and (min-width: 1800px) {
    .social-menu {
        bottom: 10px;
        right: auto;
        height: 60px;
        max-width: 300px;
        width: 100%;
        flex-direction: row;
    }
}

.social-logo {
    font-size: 35px;
    transition: 0.5s;
}

.social-link {
    color: var(--white-color);
    transition: 0.5s;
}

.social-link:hover .social-logo {
    color: var(--alternate-color);
    transition: 0.5s;
}