:root {
  --white-color: #fffdfa;
  --primary-color: #4831d4;
  --dark-primary: #302097;
  --alternate-color: #ccf381;
  --platinum: #e5e5e5;
  --grey: #8c8c8c;
  --dark-grey: #333333;
  --mid-grey: #4d4d4d;
  --page-margins: 25px 20px;
  --max-content-area: 1800px;
}

@font-face {
  font-family: 'Tauri';
  src: url('./fonts/Tauri-Regular.ttf');
  font-display: swap;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Tauri';
  font-weight: 400;
  box-sizing: border-box;
  border: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #ffffff var(--grey);
}

textarea::-webkit-scrollbar, 
body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

textarea::-webkit-scrollbar-track, 
body::-webkit-scrollbar-track {
  background: #ffffff;
}

textarea::-webkit-scrollbar-thumb, 
body::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: 5px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Tauri', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tick {
  height: 5px;
  width: 35px;
  background-color: var(--primary-color);
  margin: 0 auto;
  border-radius: 5px;
}
