.page-styles {
    font-size: 1em;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-bottom: 30px;
    padding: 20px;
}

.top-section {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    text-align: center;
    margin: 0 auto;
    padding: 0 20px;
}

.top-section h1 {
    letter-spacing: 0.5px;
}

.top-section h2 {
    letter-spacing: 1px;
    text-transform: uppercase;
}

.link {
    color: var(--primary-color);
    display: inline;
    line-height: normal;
    font-size: 2em;
    margin: 0.67em 0;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    cursor: pointer;
}

.bottom-section {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
}

.reading-section {
    font-weight: lighter;
    margin: 10px 0;
    line-height: 1.5em;
}

.reading-section:first-child {
    margin-top: 20px;
    margin-bottom: 0 !important;
}

.project-image {
    max-width: 400px;
    width: 100%;
    margin: 20px auto;
}

.skill-group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;
}

.skill {
    background-color: var(--platinum);
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 15px var(--platinum);
    font-size: 0.85em;
}