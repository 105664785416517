.project {
    color: #000000 !important;
    cursor: default;
}

.nav ul {
    display: flex;
    gap: 8px;
    list-style-type: none;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    max-width: var(--max-content-area);
    margin: 12px auto;
}

@media only screen and (max-width: 500px) {
    .nav {
        font-size: 0.75em;
    }

    .nav ul {
        gap: 4px;
    }
}

.nav ul li a {
    text-decoration: none;
    color: var(--dark-grey);
}

.sub-nav-link {
    color: var(--primary-color) !important;
}